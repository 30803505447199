import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './Components/Navbar/Navbar'
import Landing from './Components/Landing/Landing'
import Ventures from './Components/Ventures/Ventures'
import Projects from './Components/Projects/Projects'

import SneakerSeeker from './CaseStudies/SneakerSeeker'
import Resellology from './CaseStudies/Resellology'
import BlackBeard from './CaseStudies/BlackBeard'
import GravityProxies from './CaseStudies/GravityProxies'

import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";

function MainPage() {
  return (
    <div>
      <Landing />
      <Ventures />
      <Projects />
    </div>
  );
}

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/sneakerseeker" element={<SneakerSeeker />} />
        <Route path="/resellology+" element={<Resellology />} />
        <Route path="/blackbeard" element={<BlackBeard />} />
        <Route path="/gravityproxies" element={<GravityProxies />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
