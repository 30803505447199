import React, { useState } from 'react'
import './Navbar.scss';
import logo from '../../Images/logo.svg'
import { Link } from "react-router-dom";

function Navbar() {

    const [navbar, setNavbar] = useState(false)

    const changeBackground = () => {
        if (window.scrollY >= 18) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }

    window.addEventListener('scroll', changeBackground);

    return (
        <div id='navbar'>
            <div className='navbarWidth'>
                <div>
                    <Link to="/" style={{ opacity: 1 }}><img src={logo} alt="Arkham" className="navlogo" /></Link>
                </div>
                {/* <div className='navLinks'>
                    <a href="">Ventures</a>
                    <a href="">Portfolio</a>
                </div> */}
                <div>
                    <a href="mailto:adrian@ephx.com" target='_blank' className='contactButton'>Contact</a>
                </div>
            </div>
        </div>
    )
}

export default Navbar
