import React from 'react'
import './CaseStudy.scss';
import gravityBanner from '../Images/gravityproxiesbanner.png'

import globe from '../Images/globe.svg'
import twitter from '../Images/twitter.svg'

function GravityProxies() {
    return (
        <div id='casestudy'>
            <div className='articleBody'>
                <div className='articleGrid1'>
                    <img src={gravityBanner} alt="Gravity Proxies" className='articleImage' />
                    <div>
                        <div className='ventureHeader'>
                            <h1>Gravity Proxies</h1>
                            <h3>Founder & Developer (2019 - Present)</h3>
                        </div>

                        <a className='linkGroup' href='https://gravityproxies.com/' target='_blank'>
                            <img src={globe} alt="globe" className='linkIcon' />
                            <p>gravityproxies.com</p>
                        </a>

                        <a className='linkGroup' href='https://twitter.com/gravity_proxies' target='_blank'>
                            <img src={twitter} alt="twitter" className='linkIcon' />
                            <p>gravity_proxies</p>
                        </a>

                        <p className='ventureDescription'>Gravity Proxies provides fast and affordable proxies to sneaker resellers. With a focus on speed, security, and efficiency, Gravity Proxies ensures seamless browsing, data scraping, and anonymity for users. Trust Gravity Proxies to elevate your online activities with their cutting-edge proxy solutions.</p>
                    </div>
                </div>

                <h2>The Problem</h2>
                <p>In the proxy services market, resellers were struggling to secure limited sneakers while avoiding detection. Existing proxy companies were overcharging for their services, making it difficult for resellers to maximize their profits. Additionally, many proxy providers faced issues with their proxies getting banned or blacklisted, resulting in failed sneaker releases and frustrated resellers.</p>

                <h2>The Solution</h2>
                <p>At Gravity Proxies, we set out to solve these challenges by delivering a cost-effective solution that addressed the pain points of resellers. We offered proxies at a more affordable price point, enabling resellers to optimize their profit margins. But we didn't stop there. We integrated cutting-edge technologies into our proxies, allowing users to go undetected while attempting to secure limited sneaker releases. This innovation significantly increased the success rate of our resellers, giving them a competitive edge in the market.</p>

                <h2>My Role</h2>
                <p>As the founder of Gravity Proxies, I played a pivotal role in developing and launching the platform. Alongside my co-owner, we conceptualized the ideas that formed the foundation of Gravity Proxies. I oversaw all aspects of the business, from branding and marketing strategies to networking and partnerships. By building strong relationships and establishing trust within the reselling community, we positioned Gravity Proxies as a reliable and innovative solution for resellers.</p>
                <p>As the platform evolved, my focus shifted towards development. I took the lead in updating our website, payment system, and user dashboard to better serve our customers. With a relentless pursuit of excellence, I ensured that Gravity Proxies remained at the forefront of technological advancements in the proxy services industry. My goal was to provide resellers with a seamless user experience and the tools they needed to succeed.</p>

                <h2>Conclusion</h2>
                <p>Gravity Proxies has revolutionized the proxy services market by offering affordable and undetectable proxies, specifically tailored to the needs of resellers aiming to secure limited sneakers. Our innovative approach and relentless commitment to our customers have made Gravity Proxies a trusted partner for resellers, providing them with a competitive advantage in their sneaker reselling endeavors.</p>
                <p>To date, Gravity Proxies has achieved remarkable success, grossing more than $500k dollars in sales. This milestone not only reflects the effectiveness of our solution but also highlights the trust and confidence that resellers have placed in us. As we continue to grow, our focus remains on delivering exceptional value to our customers and expanding our reach in the reselling community.</p>

            </div>
        </div>
    )
}

export default GravityProxies