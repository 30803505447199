import React from 'react'
import './CaseStudy.scss';
import ssBanner from '../Images/sneakerseekerbanner.png'

import globe from '../Images/globe.svg'
import instagram from '../Images/instagram.svg'
import twitter from '../Images/twitter.svg'

function SneakerSeeker() {
    return (
        <div id='casestudy'>
            <div className='articleBody'>
                <div className='articleGrid1'>
                    <img src={ssBanner} alt="Sneaker Seeker" className='articleImage' />
                    <div>
                        <div className='ventureHeader'>
                            <h1>Sneaker Seeker</h1>
                            <h3>Founder & CEO (2019 - Present)</h3>
                        </div>

                        <a className='linkGroup' href='https://sneakerseeker.io/' target='_blank'>
                            <img src={globe} alt="globe" className='linkIcon' />
                            <p>sneakerseeker.io</p>
                        </a>

                        <a className='linkGroup' href='https://www.instagram.com/sneakerseekerio/' target='_blank'>
                            <img src={instagram} alt="instagram" className='linkIcon' />
                            <p>sneakerseekerio</p>
                        </a>

                        <a className='linkGroup' href='https://twitter.com/SneakerSeeker' target='_blank'>
                            <img src={twitter} alt="twitter" className='linkIcon' />
                            <p>sneakerseeker</p>
                        </a>

                        <p className='ventureDescription'>Sneaker Seeker is your go-to platform for finding coveted limited-edition sneakers at the most affordable prices. Our innovative approach involves aggregating price data from trusted and authentic sneaker marketplaces, ensuring that you have access to only genuine products. We also offer coverage of upcoming releases and the latest sneaker news.</p>
                    </div>
                </div>

                <h2>The Problem</h2>
                <p>In the world of sneaker consumers, finding authentic limited-edition sneakers at affordable prices can be a daunting task. The market is flooded with counterfeit products, and pricing discrepancies across various platforms make it challenging to secure the best deal. Sneaker consumers needed a reliable solution to streamline their search and ensure they are getting legitimate sneakers without breaking the bank.</p>

                <h2>The Solution</h2>
                <p>Sneaker Seeker emerged as the ultimate platform to address the problem. By aggregating price data from trusted and authentic sneaker marketplaces, Sneaker Seeker provides users with a one-stop destination to find limited sneakers at the most affordable prices. The platform's sophisticated algorithms and comprehensive database enable users to compare prices, ensuring transparency and confidence in their purchasing decisions. Additionally, Sneaker Seeker goes beyond pricing by offering up-to-date information on upcoming releases and sneaker news, creating a comprehensive resource for sneaker consumers.</p>

                <h2>My Role</h2>
                <p>In my role as the founder of Sneaker Seeker, I oversaw the development, design, and marketing aspects of the platform. I managed the staff team, handled financials, and came up with all of the ideas and concepts that have shaped Sneaker Seeker into the innovative platform it is today. From its inception, I have been the driving force behind creating a seamless user experience and ensuring Sneaker Seeker's commitment to delivering reliable and affordable limited sneakers to our valued customers.</p>

                <h2>The Conclusion</h2>
                <p>Sneaker Seeker has successfully bridged the gap between sneaker consumers and affordable, authentic limited sneakers. By providing a solution to the problem of counterfeit products and pricing inconsistencies, Sneaker Seeker has become a trusted resource for sneaker consumers worldwide. With its comprehensive approach, Sneaker Seeker continues to empower users by simplifying their search, offering valuable information, and fostering a community passionate about sneakers.</p>
                <p>Since our soft launch in the Apple App Store in January 2021, Sneaker Seeker has garnered tremendous traction. We are proud to share that we have received over 15,000 downloads and have amassed a dedicated following of 25,000 Instagram followers. The overwhelming response demonstrates the demand for a platform like Sneaker Seeker in the sneaker community.</p>
                <p>As we continue to refine and enhance the platform, we are excited to announce that we are currently in our final development phase before our official launch in September. We are also in discussions with venture capitalists to raise funding, which will further accelerate our growth and expansion.</p>
            </div>
        </div>
    )
}

export default SneakerSeeker