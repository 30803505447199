import React from 'react'
import './Ventures.scss'
import sneakerseekerLogo from '../../Images/sneakerseekerlogo.png'
import gravityproxiesLogo from '../../Images/gravityproxieslogo.png'
import resellologyLogo from '../../Images/resellologylogo.png'
import blackbeardLogo from '../../Images/blackbeardlogo.png'

import { Link } from "react-router-dom";

function Ventures() {
    return (
        <div id='ventures'>
            <h1>My Ventures</h1>
            <h2>Exploring Entrepreneurial Endeavors and Digital Innovations.</h2>

            <div className='ventureGrid'>
                <a href="/sneakerseeker" className='ventureCard' style={{ textDecoration: 'none' }}>
                    <div className='ventureHeader'>
                        <img src={sneakerseekerLogo} alt="Sneaker Seeker" className='logo' />
                        <h3>Sneaker Seeker</h3>
                    </div>
                    <p>Sneaker Seeker is your go-to platform for finding coveted limited-edition sneakers at the most affordable prices. Our innovative approach involves aggregating price data from trusted and authentic sneaker marketplaces, ensuring that you have access to only genuine products. We also offer coverage of upcoming releases and the latest sneaker news. </p>
                </a>
                <a href="/gravityproxies" className='ventureCard' style={{ textDecoration: 'none' }}>
                    <div className='ventureHeader'>
                        <img src={gravityproxiesLogo} alt="Sneaker Seeker" className='logo' />
                        <h3>Gravity Proxies</h3>
                    </div>
                    <p>Gravity Proxies provides fast and affordable proxies to sneaker resellers. With a focus on speed, security, and efficiency, Gravity Proxies ensures seamless browsing, data scraping, and anonymity for users. Trust Gravity Proxies to elevate your online activities with their cutting-edge proxy solutions.</p>
                </a>
                <a href="/resellology+" className='ventureCard' style={{ textDecoration: 'none' }}>
                    <div className='ventureHeader'>
                        <img src={resellologyLogo} alt="Sneaker Seeker" className='logo' />
                        <h3>Resellology+</h3>
                    </div>
                    <p>Resellology+ is a community built to help members learn to resell sneakers & other limited items with a highly experienced experts, exclusive information, and a in-depth guides, we empower members to excel in this competitive market. We give our members a competitive edge and maximize their profit potential in reselling.</p>
                </a>
                <a href="/blackbeard" className='ventureCard' style={{ textDecoration: 'none' }}>
                    <div className='ventureHeader'>
                        <img src={blackbeardLogo} alt="Sneaker Seeker" className='logo' />
                        <h3>BlackBeard</h3>
                    </div>
                    <p>Blackbeard is an advanced NFT analytics tool that empowers investors and collectors with valuable insights. It offers real-time tracking of profit/loss, detailed sales analysis, and simplified tax reporting. With its powerful features and user-friendly interface, Blackbeard helps users optimize their NFT strategies and navigate the dynamic NFT market with confidence.</p>
                </a>
            </div>
        </div>
    )
}

export default Ventures