import React from 'react'
import './CaseStudy.scss';
import resellologyBanner from '../Images/resellologybanner.png'

import globe from '../Images/globe.svg'
import instagram from '../Images/instagram.svg'

function Resellology() {
    return (
        <div id='casestudy'>
            <div className='articleBody'>
                <div className='articleGrid1'>
                    <img src={resellologyBanner} alt="Resellology+" className='articleImage' />
                    <div>
                        <div className='ventureHeader'>
                            <h1>Resellology+</h1>
                            <h3>Founder & Developer (2022 - Present)</h3>
                        </div>

                        <a className='linkGroup' href='https://resellology.io/' target='_blank'>
                            <img src={globe} alt="globe" className='linkIcon' />
                            <p>resellology.io</p>
                        </a>

                        <a className='linkGroup' href='https://www.instagram.com/resellology/' target='_blank'>
                            <img src={instagram} alt="instagram" className='linkIcon' />
                            <p>resellology</p>
                        </a>

                        <p className='ventureDescription'>Resellology+ is a community built to help members learn to resell sneakers & other limited items with a highly experienced experts, exclusive information, and a in-depth guides, we empower members to excel in this competitive market. We give our members a competitive edge and maximize their profit potential in reselling.</p>
                    </div>
                </div>

                <h2>The Problem</h2>
                <p>In the sneaker reselling industry, aspiring resellers faced a steep learning curve and high entry barriers. Getting into the sneaker reselling industry was not only challenging but also expensive. Existing services charged exorbitant fees while failing to provide comprehensive information needed for success. Additionally, keeping up with the latest releases, sitelists, and surprise drops was nearly impossible without a dedicated team of experts constantly monitoring the market.</p>

                <h2>The Solution</h2>
                <p>Resellology+ emerged as the ultimate solution, addressing the challenges faced by aspiring resellers. Our platform offers a community of expert resellers with 7+ years of experience in the field. Unlike other services, Resellology+ goes beyond sneakers, providing expertise in various limited-item reselling categories. We offer a range of resources and tools, including tutorials, guides, and access to a community forum. Additionally, our auto checkout service simplifies the purchasing process by leveraging our software to secure limited releases on behalf of subscribers.</p>

                <h2>My Role</h2>
                <p>As the founder of Resellology+, I have been at the forefront of developing the platform and ensuring its success. In addition to overseeing all aspects of the business, I am personally leading the development of a mobile app to enhance the user experience and streamline content accessibility. By leveraging my technical expertise, I am dedicated to making Resellology+ a user-friendly and seamless platform for aspiring resellers.</p>

                <h2>The Conclusion</h2>
                <p>Resellology+ has successfully addressed the challenges faced by aspiring resellers in the limited-item reselling market. By offering a comprehensive platform with a community of expert resellers, Resellology+ has become the go-to resource for aspiring resellers looking to overcome the learning curve and gain valuable insights. Our auto checkout service further simplifies the purchasing process, providing subscribers with a hassle-free experience.</p>
                <p>As we continue to evolve, we are excited to announce the development of a mobile app, which will elevate the user experience and make content easily accessible. With my ongoing commitment to innovation and user-centric development, Resellology+ is poised to remain at the forefront of the limited-item reselling market, empowering aspiring resellers and helping them succeed in their ventures.</p>
            </div>
        </div>
    )
}

export default Resellology