import React from 'react'
import './CaseStudy.scss';
import blackbeardVideo from '../Videos/blackbeard.mov'

import globe from '../Images/globe.svg'

function BlackBeard() {
    return (
        <div id='casestudy'>
            <div className='articleBody'>
                <div className='articleGrid1'>
                    <video className='articleImage' controls muted autoPlay>
                        <source src={blackbeardVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div>
                        <div className='ventureHeader'>
                            <h1>Black Beard</h1>
                            <h3>Developer (2022 - Present)</h3>
                        </div>

                        <a className='linkGroup' href='https://www.blackbeard.xyz/' target='_blank'>
                            <img src={globe} alt="globe" className='linkIcon' />
                            <p>blackbeard.xyz (old website)</p>
                        </a>

                        <p className='ventureDescription'>Blackbeard is an advanced NFT analytics tool that empowers investors and collectors with valuable insights. It offers real-time tracking of profit/loss, detailed sales analysis, and simplified tax reporting. With its powerful features and user-friendly interface, Blackbeard helps users optimize their NFT strategies and navigate the dynamic NFT market with confidence.</p>
                    </div>
                </div>

                <h2>The Problem</h2>
                <p>In the realm of NFT investments and collections, investors and collectors lacked comprehensive analytics and tracking tools to gain insights and control over their portfolios. Existing solutions fell short in providing the necessary tools and information to make informed decisions and optimize their NFT strategies.</p>

                <h2>The Solution</h2>
                <p>Blackbeard emerged as a cutting-edge analytics and tracking tool designed to empower NFT investors and collectors with unparalleled insights and control over their portfolios. Users can seamlessly track the profit and loss of their NFT investments in real-time, gain valuable intelligence from detailed past sales analysis, monitor the overall value of their collection, and simplify tax reporting with easy export functionality. Blackbeard equips users with the tools to optimize their NFT strategies, offering a comprehensive platform that combines powerful analytics with a user-friendly interface.</p>

                <h2>My Role</h2>
                <p>As a developer for Blackbeard, I played a crucial role in the company's success. Utilizing my expertise in working with APIs, I leveraged various data sources to pull and display valuable information and analytics within the Blackbeard platform. I developed the electron app using React JS, enabling users to easily access the platform and its features. Additionally, I took charge of designing both the desktop application and website, ensuring a seamless and visually appealing user experience.</p>

                <h2>Conclusion</h2>
                <p>Blackbeard has successfully addressed the challenges faced by NFT investors and collectors by providing a cutting-edge analytics and tracking tool. With its powerful features and user-friendly interface, Blackbeard empowers users to gain valuable insights, optimize their NFT strategies, and navigate the unpredictable waters of the NFT ecosystem with confidence and precision. As a developer for Blackbeard, my commitment to continuous improvement and innovation ensures that the platform remains at the forefront of the ever-evolving NFT market, delivering exceptional value to our users.</p>
            </div>
        </div>
    )
}

export default BlackBeard