import React from 'react'
import './Projects.scss';

// Images
import capncovers from '../../Images/projects/capncovers.png'
import tigerstreak from '../../Images/projects/tigerstreak.png'
import frugalseason from '../../Images/projects/frugalseason.png'
import famil from '../../Images/projects/famil.png'
import unity from '../../Images/projects/unity.png'
import bloodytoothguy from '../../Images/projects/bloodytoothguy.png'
import resellersbasement from '../../Images/projects/resellersbasement.png'
import freebiefrenzy from '../../Images/projects/freebiefrenzy.png'
import youbypeace from '../../Images/projects/youbypeace.png'

function Projects() {
    return (
        <div id='projects'>
            <h1>Website Portfolio</h1>
            <h2>Highlighting Cutting-Edge Projects and Digital Solutions.</h2>

            <div className='projectsGrid'>
                <a href="https://capncovers.com/" target="_blank" className='projectContainer'>
                    <img src={capncovers} alt="capncovers" className='coverImage' />
                    <div className='overlay'>
                        <p className='overlayText'>capncovers</p>
                        <div className='roleCarousel'>
                            <div className='roleContainer'>Design</div>
                            <div className='roleContainer'>Frontend</div>
                            <div className='roleContainer'>Backend</div>
                        </div>
                    </div>
                </a>
                <a href="https://www.frugalseason.com/" target="_blank" className='projectContainer'>
                    <img src={frugalseason} alt="frugalseason" className='coverImage' />
                    <div className='overlay'>
                        <p className='overlayText'>Frugal Season</p>
                        <div className='roleCarousel'>
                            <div className='roleContainer'>Frontend</div>
                            <div className='roleContainer'>Producer</div>
                        </div>
                    </div>
                </a>
                <a href="https://www.tigerstreak.io/" target="_blank" className='projectContainer'>
                    <img src={tigerstreak} alt="tigerstreak" className='coverImage' />
                    <div className='overlay'>
                        <p className='overlayText'>Tiger Streak</p>
                        <div className='roleCarousel'>
                            <div className='roleContainer'>Frontend</div>
                        </div>
                    </div>
                </a>
                <a href="https://www.familusa.org/" target="_blank" className='projectContainer'>
                    <img src={famil} alt="famil" className='coverImage' />
                    <div className='overlay'>
                        <p className='overlayText'>Famil USA</p>
                        <div className='roleCarousel'>
                            <div className='roleContainer'>Frontend</div>
                            <div className='roleContainer'>Producer</div>
                        </div>
                    </div>
                </a>
                <a href="https://www.unity3.io/" target="_blank" className='projectContainer'>
                    <img src={unity} alt="unity 3" className='coverImage' />
                    <div className='overlay'>
                        <p className='overlayText'>Unity 3</p>
                        <div className='roleCarousel'>
                            <div className='roleContainer'>Frontend</div>
                            <div className='roleContainer'>Producer</div>
                        </div>
                    </div>
                </a>
                <a href="https://bloodytoothguy.com/" target="_blank" className='projectContainer'>
                    <img src={bloodytoothguy} alt="bloodytoothguy" className='coverImage' />
                    <div className='overlay'>
                        <p className='overlayText'>Bloody Tooth Guy</p>
                        <div className='roleCarousel'>
                            <div className='roleContainer'>Design</div>
                            <div className='roleContainer'>Frontend</div>
                            <div className='roleContainer'>Producer</div>
                        </div>
                    </div>
                </a>
                <a href="https://resellers-basement.com/" target="_blank" className='projectContainer'>
                    <img src={resellersbasement} alt="Resellers Basement" className='coverImage' />
                    <div className='overlay'>
                        <p className='overlayText'>Resellers Basement</p>
                        <div className='roleCarousel'>
                            <div className='roleContainer'>Frontend</div>
                            <div className='roleContainer'>Producer</div>
                        </div>
                    </div>
                </a>
                <a href="https://www.freebiefrenzy.io/" target="_blank" className='projectContainer'>
                    <img src={freebiefrenzy} alt="Freebie Frenzy" className='coverImage' />
                    <div className='overlay'>
                        <p className='overlayText'>Freebie Frenzy</p>
                        <div className='roleCarousel'>
                            <div className='roleContainer'>Frontend</div>
                            <div className='roleContainer'>Producer</div>
                        </div>
                    </div>
                </a>
                {/* <a href="https://youbypeace.xyz/" target="_blank" className='projectContainer'>
                    <img src={youbypeace} alt="You By Peace" className='coverImage' />
                    <div className='overlay'>
                        <p className='overlayText'>You By Peace</p>
                        <div className='roleCarousel'>
                            <div className='roleContainer'>Frontend</div>
                        </div>
                    </div>
                </a> */}
            </div>
        </div>
    )
}

export default Projects