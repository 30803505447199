import React from 'react';
import './Landing.scss';

function Landing() {
  return (
    <div id='landing'>
      <div className='landingContainer'>
        <div>
          <h2>Adrian Kehlmann </h2>
          <h1>Unlocking the digital realm with innovative solutions.</h1>
          <p>I'm Adrian Kehlmann, an 18 year old entrepreneur from New York City. With my expertise in software development, I specialize in creating seamless digital experiences that merge functionality and design. I bring ideas to life with precision and creativity. Let's collaborate to bring your ideas to life.</p>
          <a href="mailto:adrian@ephx.com" target='_blank' className='contactButton'>Contact Me</a>
        </div>
        <div className='myInfo'>
          <div>
            <h3>My Roles</h3>
            <div className='infoCarousel'>
              <div className='infoContainer'>💻 Digital Production</div>
              <div className='infoContainer'>👨‍💻 Development</div>
              <div className='infoContainer'>🎨 Design</div>
              <div className='infoContainer'>🛒 Marketing</div>
            </div>
          </div>
          <div>
            <h3>My Hobbies</h3>
            <div className='infoCarousel'>
              <div className='infoContainer'>👟 Sneakers</div>
              <div className='infoContainer'>🎮 Gaming</div>
              <div className='infoContainer'>🏂 Snowboarding</div>
              <div className='infoContainer'>📈 Investing</div>
            </div>
          </div>
          <div>
            <h3>My Socials</h3>
            <div className='infoCarousel'>
              <a href="https://www.instagram.com/arkhamnyc/" target='_blank'><div className='infoContainer'>📸 Instagram</div></a>
              <a href="https://twitter.com/Arkham_NYC" target='_blank'><div className='infoContainer'>🐥 Twitter</div></a>
              <a href="mailto:adrian@ephx.com" target='_blank'><div className='infoContainer'>📩 Email</div></a>
              <a href="https://t.snapchat.com/l4akLSdv" target='_blank'><div className='infoContainer'>👻 Snapchat</div></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;

